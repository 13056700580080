import React from "react";

const OurVision = () => {
  return (
    <React.Fragment>
      <div className="location-area ptb-70 our-vision">
        <div className="container">
          <div className="row location-wrap">
            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <div className="location-item">
                <h3>
                  <img
                    src="/images/binoculars.png"
                    alt=""
                    className="img-fluid"
                  />{" "}
                  Vision
                </h3>
                <p>
                  To provide imaging and laboratory services in a professional
                  manner at par with international standards of excellence.
                </p>
              </div>
            </div>

            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <div className="location-item">
                <h3>
                  <img src="/images/mission.png" alt="" className="img-fluid" />{" "}
                  Mission
                </h3>
                <p>
                  Our mission is to continue as a centre of excellence for
                  provision of accurate diagnosis by deploying state-of-the-art
                  technology with optimum consideration for patient care and
                  comfort.
                </p>
              </div>
            </div>

            <div className="col-sm-12 col-lg-4 col-md-4 mb-15">
              <div className="location-item">
                <h3>
                  <img src="/images/target.png" alt="" className="img-fluid" />{" "}
                  Aim
                </h3>
                <p>
                  To assist clinicians in reaching an accurate diagnosis. | To
                  provide latest technology at affordable cost. | To provide
                  diagnostic services with professional ethics. | To provide all
                  measures for optimum patient care and comfort.
                </p>
              </div>
            </div>

            <div className="col-sm-12 col-lg-12 col-md-12">
              <div className="location-item">
                <h3 className="nk__text-left">
                  <img
                    src="/images/quality-icon.png"
                    alt=""
                    className="img-fluid"
                  />{" "}
                  Quality Policy
                </h3>
                <p>
                  Dr. Doda’s Diagnostics & Healthcare is committed to follow
                  good professional practice and provide quality, timely and
                  patient friendly services with the sole aim of ensuring proper
                  diagnosis with compassion, integrity, teamwork & honesty.
                </p>
                <p>
                  We continuously strive to improve the quality of our services
                  by complying with International Standards and fulfil our set
                  objectives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OurVision;
