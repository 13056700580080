import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const NeurologyPage = () => {
  return (
    <React.Fragment>
      <div className="nk__qualityAssurance ptb-70 nk__radiology_page nk__faclities_sub-page">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Neurology</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="nk__tab_content">
                <p>
                  <strong>Electroencephalography (EEG)</strong> is the recording
                  of electrical activity of the brain, over a short period of
                  time, usually 20-40 minutes, as recorded from multiple
                  electrodes placed on the scalp. In neurology, the main
                  diagnostic application of EEG is in the case of epilepsy, as
                  epileptic activity can create clear abnormalities on a
                  standard EEG study. A secondary clinical use of EEG is in the
                  diagnosis of coma, encephalopathies, and brain death.
                </p>
                <p className="nk__content_title">EMG</p>
                <p>
                  <strong>An electromyogram (EMG)</strong> is a test that is
                  used to record the electrical activity of muscles. When
                  muscles are active, they produce an electrical current. This
                  current is usually proportional to the level of the muscle
                  activity. An EMG is also referred to as a myogram.
                </p>
                <p className="nk__content_title">NCV</p>
                <p>
                  A nerve conduction velocity test (NCV) is an electrical test
                  that is used to determine the adequacy of the conduction of
                  the nerve impulse as it courses down a nerve. This test is
                  used to detect signs of nerve injury. In this test, the nerve
                  is electrically stimulated, and the electrical impulse
                  downstream from the stimulus is measured. This is usually done
                  with surface patch electrodes (they are similar to those used
                  for an electrocardiogram) that are placed on the skin over the
                  nerve at various locations. One electrode stimulates the nerve
                  with a very mild electrical impulse. The resulting electrical
                  activity is recorded by the other electrodes. The distance
                  between electrodes and the time it takes for electrical
                  impulses to travel between electrodes are used to calculate
                  the speed of impulse transmission (nerve conduction velocity).
                  A decreased speed of transmission indicates nerve disease. A
                  nerve conduction velocity test is often done at the same time
                  as an electromyogram (EMG) in order to exclude or detect
                  muscle conditions.
                </p>

                <p className="nk__content_title">BERA</p>
                <p>
                  <strong>BERA (Brainstem evoked response audiometry)</strong> ,
                  ABR (Auditory brain stem response), BAER (Brainstem auditory
                  evoked response audiometry).
                </p>
                <p>
                  <strong>BERA</strong> is an electro-physiological test
                  procedure which studies the electrical potential generated at
                  the various levels of the auditory system starting from
                  cochlea to cortex.
                </p>

                <p className="nk__content_title">VEP</p>
                <p>
                  Visual Evoked Potential is used to detect lesions of the optic
                  nerve, or inflammation of the optic nerve (optic neuritis),
                  and also optic nerve demyelination as with demyelinating
                  disease (Multiple Sclerosis).
                </p>

                <p className="nk__content_title text-center nk__block">
                  Instructions for patients
                </p>

                <p>
                  We recommend you to take appointment for neurology
                  investigations to avoid waiting time at the centre.
                </p>
                <p>
                  For EEG investigation patient should come with clean scalp.
                  Head should not be greasy and there should be no oil in hair
                  prior to investigation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NeurologyPage;
