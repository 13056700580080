import React from "react";
import ContactInfo from "../components/ContactInfo";
import ContactForm from "../components/ContactForm";
import GoogleMap from "../components/GoogleMap";

const ContactPage = () => {
  return (
    <React.Fragment>
      <ContactInfo />
      <ContactForm />
      <GoogleMap />
    </React.Fragment>
  );
};

export default ContactPage;
