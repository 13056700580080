import { ACTION_TYPES } from "../constants/actionTypes";

export const healthPackagesInitialState = {
  healthPackages: [],
};

export const healthPackagesReducer = (
  state = healthPackagesInitialState,
  action
) => {
  switch (action.type) {
    case ACTION_TYPES.SET_HEALTH_PACKAGES:
      return {
        ...state,
        healthPackages: [...action.payload],
      };
    case ACTION_TYPES.ADD_HEALTH_PACKAGES:
      return {
        ...state,
        healthPackages: [...state.healthPackages, ...state.healthPackages],
      };
    default:
      return state;
  }
};
