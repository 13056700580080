import React, { useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useAxios from "../../custom-hooks/useAxios";
import axios from "../../services/apis/healthPackagesApi";
import Loader from "../../components/common/Loader/Loader";

import "./HealthPackagePage.scss";

const API_ID =
  "AKfycbwR0phS094quQjeEjpKg_vU6TfACzMcGRaAkjsboMUWGvGPVHZDWboRez0t_nUwoPjR/exec";

const HealthPackagePage = () => {
  const { id: packageId } = useParams();

  const [loading, response, error, axiosFetch] = useAxios();

  useEffect(() => {
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `/${API_ID}`,
    });
  }, [axiosFetch]);

  const healthPackage = response?.filter((el) => el.id === Number(packageId));

  return (
    <div className="nk__health-package-page ptb-70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="section-title">
              <h2>
                {healthPackage &&
                  healthPackage.length > 0 &&
                  healthPackage[0].title}
              </h2>
            </div>

            <div className="nk__health-packages">
              <Row>
                {loading && !error && (
                  <Col md={12}>
                    <Loader info="Loading Health Package. Please wait..." />
                  </Col>
                )}

                {error && !loading && (
                  <Col md={12}>
                    <p>{error}</p>
                    <Loader />
                  </Col>
                )}

                {healthPackage && healthPackage.length > 0 && (
                  <>
                    <Col lg={5}>
                      <img
                        src={healthPackage[0].imgUrl}
                        alt={healthPackage[0].title}
                        className="nk__health-package__img"
                      />
                    </Col>
                    <Col lg={6}>
                      <div className="nk__health-package__info">
                        <p className="nk__health-package__info-title">
                          {healthPackage[0].title}
                        </p>
                        <p className="nk__health-package__info-des">
                          {healthPackage[0].description}
                        </p>
                        <p className="nk__health-package__info-tests">
                          Number of tests included in package:{" "}
                          <span>{healthPackage[0].testsCount}</span>
                        </p>
                        <ul className="nk__health-package__info-tests-list">
                          {healthPackage[0]?.includedTests?.map((el, i) => {
                            return (
                              <li key={i}>
                                <i className="icofont-double-right"></i> {el}
                              </li>
                            );
                          })}
                        </ul>
                        <div>
                          <span className="nk__health-package__info-price">
                            <i className="icofont-rupee"></i>
                            {healthPackage[0].price}
                          </span>
                          <a
                            className="nk__health-package__info-book"
                            href={healthPackage[0].paymentLink}
                            target="_blank"
                          >
                            Buy Now{" "}
                            <i className="icofont-rounded-double-right"></i>
                          </a>
                        </div>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HealthPackagePage;
