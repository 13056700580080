import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const CbctPage = () => {
  return (
    <React.Fragment>
      <div className="nk__qualityAssurance ptb-70 nk__radiology_page nk__faclities_sub-page">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>CS 9600 CBCT Scanner</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="nk__tab_content">
                <p>
                  <strong>Cone-beam computed tomography (CBCT)</strong> is a
                  special type of X-ray imaging technique used in dentistry.{" "}
                  <strong>
                    It provides high-resolution, three-dimensional images of
                    teeth, gums, nerve pathways, and bone
                  </strong>{" "}
                  in a single scan.
                </p>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <p>
                    <img
                      src="/images/facilities/cbct-scanner.jpg"
                      alt=""
                      className="img-fluid mt-3"
                    />
                  </p>
                </div>
                <div className="col-md-7">
                  <p>
                    <img
                      src="/images/facilities/cbct-teeth.jpg"
                      alt=""
                      className="img-fluid mt-3"
                    />
                  </p>
                  <p className="nk__content_title">Benefits at a glance:</p>
                  <ul>
                    <li>
                      The <strong>World’s Most Intelligent</strong> CBCT
                    </li>
                    <li>
                      Scalable, <strong>5-in-1 Scanner</strong>
                    </li>
                    <li>
                      Smart <strong>Innovative</strong> Precision
                    </li>
                    <li>
                      <strong>AI-Powered</strong> Positioning
                    </li>
                    <li>
                      <strong>Crystal-Clear</strong> Images
                    </li>
                    <li>
                      Broadest Range of Volume sizes with{" "}
                      <strong>up to 14 FOV</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CbctPage;
