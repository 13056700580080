import React, { useEffect, useRef } from "react";
import { Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useIntersect from "../../../custom-hooks/useIntersect";

import "./HealthPackageCard.scss";

const HealthPackageCard = ({ data }) => {
  const containerRef = useRef(null);

  const [ref, isVisible] = useIntersect(containerRef, {
    root: null,
    rootMargin: "50px",
    threshold: 0.1,
  });

  useEffect(() => {
    if (isVisible) {
      ref.current.src = ref.current.dataset.src;
    }
  }, [isVisible, ref]);

  return (
    <Col md={3} style={{ minHeight: "200px" }}>
      <div className="nk__health-packages--card">
        <Link
          to={`/health-package/${data.id}`}
          className="nk__health-packages--card-link"
        >
          <Card>
            <div className="nk__health-packages--card-img-wrap">
              <Card.Img
                variant="top"
                src="/images/preLoaded-img.jpg"
                data-src={data.imgUrl}
                className="nk__health-packages--card-img"
                ref={containerRef}
              />
            </div>
            <Card.Body>
              <Card.Title className="nk__health-packages--card-title">
                {data.title}
              </Card.Title>
              <Card.Text className="nk__health-packages--card-tests">
                Tests Included: {data.testsCount}
              </Card.Text>
              <Card.Text className="nk__health-packages--card-des">
                {data.description}
              </Card.Text>
              <Button className="nk__health-packages--card-explore">
                Explore <i className="icofont-rounded-double-right"></i>
              </Button>
            </Card.Body>
          </Card>
        </Link>
      </div>
    </Col>
  );
};

export default HealthPackageCard;
