import React from "react";

const GoogleMap = () => {
  return (
    <div className="map__wrap ptb-35">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="map-area">
              <iframe
                title="location-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.542660399077!2d77.18411331508294!3d28.643465982413343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0297e2c854a1%3A0x77daa15e04b1a404!2sDr.+Doda's+Diagnostics+And+Healthcare!5e0!3m2!1sen!2sin!4v1488540898375"
                height="320"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMap;
