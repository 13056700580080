import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const FetalMedicinePage = () => {
  return (
    <React.Fragment>
      <div className="nk__qualityAssurance ptb-70 nk__radiology_page nk__faclities_sub-page">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Fetal Medicine</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="nk__tab_content">
                <p>
                  <strong>“Caring for babies even before birth”</strong>
                </p>
                <p>
                  We are a one stop solution for all the diagnostic needs of
                  your unborn. We have a formally trained and highly experienced
                  team to perform fetal scans of the highest standards. With
                  state of the art equipments, skilled support staff and caring
                  environment, we are committed to provide you with an enriching
                  experience. Minute detailing backed by informative counselling
                  is what we strive for.
                </p>
                <p className="nk__content_title mb-50 nk__block text-center">
                  Facilities provided:
                </p>
                <p className="nk__content_title">
                  1. Enhanced First trimester screening (including pre-eclampsia
                  screen)
                </p>
                <p>
                  Performed between 11- 13.6 weeks of pregnancy. This Scan
                  targets detection of enlarged nuchal translucency (NT),
                  considered as an early marker of multiple disorders such as
                  Down syndrome. This opportunity is also used to screen the
                  baby for early detection of lethal congenital abnormalities.
                </p>
                <p className="nk__content_title">
                  2. Level 2 Ultrasound/ TIFFA
                </p>
                <p>
                  Level 2 scan is a detailed sonographic examination of fetus
                  for detection of structural abnormalities. It is the most
                  vital scanning, your obstetrician will ask you to undergo
                  around 20 weeks of gestation. We follow all International
                  guidelines for Level 2 scanning.
                </p>

                <p className="nk__content_title">
                  3. Fetal echocardiogram (Heart evaluation)
                </p>
                <p>
                  This is an advanced ultrasonography of the fetal heart. Hear
                  we perform sequential cardiac examination to detect complex
                  heat diseases . Your doctor may advise fetal echocardiogram in
                  special circumstances to see the detailed anatomy and rhythm
                  of heart.
                </p>

                <p className="nk__content_title">
                  4. Fetal neurosonogram (Brain evaluation)
                </p>
                <p>
                  This is a dedicated, state-of-the-art imaging of the evolving
                  fetal brain. Detailed neurosongram is a cutting edge technique
                  and its interpretation is often challenging. The team of
                  experts at our centre are proficient in this. We back our
                  findings with truthful and sensitive counselling.
                </p>

                <p className="nk__content_title">5. Fetal dopplers</p>
                <p>
                  The imaging modality is performed during the later pregnancy
                  months to look at fetal growth, amniotic fluid volume and
                  fetal blood circulation. This information is used by your
                  obstetrician for delivery planning.
                </p>

                <p className="nk__content_title">6. Fetal MRI</p>
                <p>
                  With a team of senior radiologists, we routinely perform and
                  interpret fetal MRI. The same has allowed us to move a step
                  ahead in fetal care. Fetal MRI has solved the riddle in
                  complex and cryptic cases involving brain, spine and other
                  fetal anomalies which were not fully characterisable on
                  ultrasonography.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FetalMedicinePage;
