import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const AboutSection = () => {
  return (
    <React.Fragment>
      <div className="about-area ptb-70">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2> Why Patients Trust Us?</h2>
            </ScrollAnimation>
          </div>
          <div className="row align-items-center pb-70">
            <div className="col-lg-3 col-md-3">
              <div className="about-item">
                <div className="about-left">
                  <img src="/images/years-experience.png" alt="About" />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9">
              <div className="about-item about-right">
                <img src="/images/about-shape1.svg" alt="About" />
                <p>
                  Dr. Doda’s Diagnostics and Healthcare made a humble beginning
                  at Pusa Road in the year 1987. Over the years, with widened
                  spectrum of diagnostic facilities and constant up gradation,
                  the centre is presently equipped with highly sophisticated
                  equipments such as 3.0T MRI with Transmit Technology, MD CT
                  with Coronary Angio, 5 Whole body Ultrasound Color Doppler
                  Systems, , DR OPG, CBCT, Digital Mammography, Digital X-rays
                  (DR &CR Systems, Whole Body DEXA, Cardiac &
                  Neuro-physiological studies...
                </p>
                <ul>
                  <li>
                    <i className="icofont-check-circled"></i>
                    Browse Our Website
                  </li>
                  <li>
                    <i className="icofont-check-circled"></i>
                    Choose Health Package
                  </li>
                  <li>
                    <i className="icofont-check-circled"></i>
                    Send Messege
                  </li>
                </ul>
                <ScrollAnimation animateIn="fadeInUp" offset="300">
                  <Link to="/about-us">
                    Know More <i className="icofont-rounded-double-right"></i>
                  </Link>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutSection;
