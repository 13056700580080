import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

const EmpanelmentPage = () => {
  return (
    <React.Fragment>
      <div className="nk__empanelment_page">
        <div className="bg-dark-grey ptb-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <ScrollAnimation animateIn="fadeInUp" offset="100">
                    <h2>Empanelment</h2>
                  </ScrollAnimation>
                </div>

                <div className="col-12">
                  {/* <EmpanelmentNew /> */}
                  <div className="symptoms-area">
                    <div className="row align-items-center">
                      <div className="col-lg-12">
                        <div className="symptoms-content">
                          <ul>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Central Government Health Scheme (CGHS)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Delhi Government (DGEHS)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Northern Railway Central Hospital
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                BSES Rajdhani BSES Yamuna (BSES)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Delhi Jal Board (DJB)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Bharat Heavy Electricals Limited (BHEL)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Indo-Tibetan Border Police (ITBP)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Prident Estate Clinic (PEC)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Indraprastha Power Generation Company Limited
                                (IPGCL)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Delhi Transco Limited (DTL)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                University of Delhi (DU)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                New Delhi Municipal Council (NDMC)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Union Public Services Commission (UPSC)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                ESI
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Central Electricity Regulatory Commission (CERC)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Dehi Electricity Regulatory Commission (DERC)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                The Institute For The Physically Handicapped
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                National Council For Cement And Building
                                Materials (NCB)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Bureau Of Indian Standards
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                National Council For Co-operative Training
                                (NCCT)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                National Institute for Biology (NIB)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Engineers India Limited (EIL)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Indian Council of Agricultural Research (ICAR)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Telecommunication Consultants India Limited
                                (TCIL)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Sports Authority of India
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Idian Airlines Limited (IAL)
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="icofont-caret-right"></i>
                                Border Security Force (BSF)
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-lightpurple pt-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <ScrollAnimation animateIn="fadeInUp" offset="100">
                    <h2>Essentail Documents Required For Panel Patients</h2>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Accordion allowZeroExpanded preExpanded={["a"]}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>
                          CGHS SERVING EMPLOYEES (MINISTRY OF HEALTH & FAMILY
                          WELFARE /RMLH/LHMC/KALAWATI /BIKANER HOUSE)
                        </span>{" "}
                        <span>BLUE CARD</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          PRESCRIPTION WITH 14 DAYS VALIDITY & STAMP OF GOVT
                          DOCTOR.
                          <br />
                          (MO/CMO/)
                          <br />
                          (SPECIALIST/CONSULTANT/HOD/MS)
                        </li>
                        <li>
                          IDENTITY CARD AND CGHS CARD OF MAIN BENEFICIARY AND
                          ITS PHOTOCOPIES
                        </li>
                        <li>
                          CGHS VALID CARD ( RELATION, DEPARTMENT & PLACE SHOULD
                          BE WRITTEN ON CARD ) WITH ITS COPY
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="b">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>CGHS (PENSIONER)</span> <span>GREEN CARD</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          PRESCRIPTION/PERMISSION WITH 14 DAYS VALIDITY & STAMP
                          OF GOVT DOCTOR
                          <br />
                          (MO/CMO/)
                          <br />
                          (SPECIALIST/CONSULTANT/HOD/MS)
                        </li>
                        <li>
                          CGHS VALID CARD COPY OF PATIENT & MAIN CARD HOLDER
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="c">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>AUTONOMOUS BODIES</span> <span>YELLOW CARD</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          REQUIRED PERMISSION FROM AUTONOMOUS BODY PRESCRIPTION
                          VALIDITY 14 DAYS.
                        </li>
                        <li>
                          CGHS CARD OF PATEINT & MAIN CARD HOLDER WITH
                          PHOTOCOPIES.
                        </li>
                        <li>
                          NO CREDIT FACILITY WILL BE GIVEN ON PRESCRIPTON ONLY
                          PERMISSION IS NECESSARY.
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="d">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>NORTHERN RAILWAY</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>PERMISSION WITH STAMP FROM NRCH</li>
                        <li>
                          SIGNATURE OF REF DOCTOR & MEDICAL DIRECTOR OR
                          SINGNATURE OF U/I
                        </li>
                        <li>AMOUNT AS PERNRCH RATE LIST</li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="e">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>DELHI AROGYA KOSH</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          AUTHORISATION FROM DELHI GOVT
                          HOSPITALS/POLYCLINICSWITH FILE NO AND DATE
                        </li>
                        <li>
                          PERMISSION FROM NODAL OFFICER/MED SUPERINTENDENT WITH
                          SIGNATURES AND STAMP
                        </li>
                        <li>APPROVED PRESCRIPTION FROM HEAD OF DEPARTMENT</li>
                        <li>IDENTITY PROOF AND ITS COPY</li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="f">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>DGEHS</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          PERMISSION FROM DEGHSHOSPITALS/POLYCLINICS/APPROVED
                          PRIVATE HOSPITALS & NURSING HOMES WITH ONE MONTH
                          VALIDITY
                        </li>
                        <li>
                          VALID FAMILY CARD WITH PHOTOCOPY OF BOTH SIDES OF THE
                          CARD
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="g">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>RAJYA SABHA</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>PERMISSION WITH STAMP.</li>
                        <li>PRESCRIPTION WITH 14 DAYS VALIDITY.</li>
                        <li>RAJYA SABHA IDENTITY CARD</li>
                        <li>CGHS CARD COPY</li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="h">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>PRESIDENT ESTATE</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          PRESCRIPTION AND PERMISSION AGAINST BILL WITH STAMP OF
                          PRESIDENT ESTATE
                        </li>
                        <li>CGHS VALID CARD AND ITS PHOTO COPY</li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmpanelmentPage;
