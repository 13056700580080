import React from "react";

const TopHeader = () => {
  return (
    <div className="header-top">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-8 col-sm-7">
            <div className="header-top-item">
              <div className="header-top-left">
                <ul>
                  <li>
                    <a href="tel:+919643106752">
                      <i className="icofont-ui-call"></i>
                      Home Collection : +91 964 310 6752
                    </a>
                  </li>
                  {/* <li>
                    <a href="tel:+919643106760">
                      <i className="icofont-ambulance-cross"></i>
                      Ambulance : +91 964 310 6760
                    </a>
                  </li> */}
                  <li>
                    <a href="tel:+919643106760">
                      <i className="icofont-telephone"></i>
                      For Inquiry :+91 964 310 6760
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-2 col-md-3 col-sm-4">
            <div className="header-top-item">
              <div className="header-top-right">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      <i className="icofont-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i className="icofont-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i className="icofont-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          <div className="col-lg-4 col-md-4 col-sm-5">
            <div className="header-top-item">
              <div className="header-top-right">
                <a
                  className="nav-link nk__download_report active"
                  href="http://122.161.198.183/dodas/design/online_lab/default.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icofont-download"></i>Download Report
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
