import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import AboutSection from "../components/AboutSection";
import OurVision from "../components/OurVision";
import OurDoctors from "../components/OurDoctors";
import OurDoctorsTwo from "../components/OurDoctorsTwo";
import Stats from "../components/Stats";

const AboutPage = () => {
  return (
    <div className="nk__aboutus_page">
      <div className="nk__about_intro_section ptb-70">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>About Us</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="nk__aboutus_intro">
                <p>
                  <span>Dr. Doda’s Diagnostics and Healthcare</span> has over 37
                  years of experience & expertise in providing the most reliable
                  quality diagnostic healthcare services in Delhi with the sole
                  aim of ensuring proper diagnosis with compassion, integrity,
                  teamwork & honesty. We have a wide spectrum of diagnostic
                  facilities and are constantly upgrading our centre with highly
                  sophisticated equipments & skilled doctors. Our mission is to
                  continue as a centre of excellence for provision of accurate
                  diagnosis by deploying state-of-the-art technology with
                  optimum consideration for patient care and comfort.
                </p>
                <p>
                  Our Pathology Lab is fully automated with State of the Art
                  Equipment, provides a wide range of routine and specialised
                  tests and focuses on quality care of patients. The Lab has
                  NABL Accreditation from National Accreditation Board For
                  Testing And Calibration Laboratories under Department of
                  Science and Technology, Government of India.
                </p>
                <p>
                  The centre is a one stop facility for all diagnostic
                  investigations and is your destination for healthcare.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AboutSection />
      <OurVision />
      <OurDoctors />
      <OurDoctorsTwo />
      <Stats />
    </div>
  );
};

export default AboutPage;
