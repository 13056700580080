import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="ptb-70">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-feedback">
                  <h3>Awards & Certifications</h3>
                  <div className="nk__certificate">
                    <a href="#" target="_blank">
                      <img
                        src="/images/nabh-logo.png"
                        alt="nabh-logo"
                        className="img-fluid"
                      />
                      <span>MIS-2016-0034</span>
                    </a>
                  </div>
                  <div className="nk__certificate two__logo">
                    <a href="/images/nabl-Certificate.pdf" target="_blank">
                      {/* <span>
                        <img
                          src="/images/ilac-mra-logo.png"
                          alt="ilac-mra-logo"
                          className="img-fluid"
                        />
                      </span> */}
                      <span>
                        <img
                          src="/images/nabl-logo.png"
                          alt="nabh-logo"
                          className="img-fluid m-0"
                        />
                        <span>
                          ISO 15189:2012
                          <br />
                          MC-2625
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="footer-item">
                <div className="footer-contact">
                  <h3>Contact Us</h3>
                  <ul>
                    <li>
                      <i className="icofont-email"></i>
                      <a href="mailto:dodahealthcare@gmail.com">
                        dodahealthcare@gmail.com
                      </a>
                    </li>
                    <li>
                      <i className="icofont-ui-call"></i>
                      <a href="tel:+919643106759">Call: +91 9643106759</a>
                      <a href="tel:+919643106760">Call: +91 9643106760</a>
                    </li>
                    <li>
                      <i className="icofont-telephone"></i>
                      <a href="tel:01143006300">Call: 011 - 4300 6300</a>
                    </li>
                    <li>
                      <i className="icofont-fax"></i>
                      <a href="fax:+911143006363">Fax: +(91)-(11)-4300 6363</a>
                    </li>
                    <li>
                      <i className="icofont-location-pin"></i>
                      23-B, Pusa Road, Opposite Metro Pillar 115, New Delhi -
                      110005, India.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-quick nk__quick-links">
                  <h3>Quick Links</h3>
                  <ul>
                    <li>
                      <i className="icofont-download-alt"></i>
                      <a
                        href="http://122.161.198.183:8081/dodas/design/online_lab/default.aspx"
                        target="_blank"
                      >
                        Report Download
                      </a>
                    </li>
                    <li>
                      <i className="icofont-download"></i>
                      <Link to="/">Download Brochures</Link>
                    </li>
                    <li>
                      <i className="icofont-calendar"></i>
                      <a href="https://wa.me/919643106760" target="_blank">
                        Get Appointment
                      </a>
                    </li>
                    <li>
                      <i className="icofont-ambulance-cross"></i>
                      <a href="tel:+919643106760">Ambulance</a>
                    </li>
                  </ul>
                </div>
                <div className="header-top-item">
                  <div className="header-top-right">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/DrDodasDiagnostics/"
                          target="_blank"
                        >
                          {/* <i className="icofont-facebook"></i> */}
                          <img
                            src="/images/facebook.png"
                            className="img-fluid"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank">
                          {/* <i className="icofont-twitter"></i> */}
                          <img
                            src="/images/twitter.png"
                            className="img-fluid"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://in.linkedin.com/company/dodahealthcare"
                          target="_blank"
                        >
                          {/* <i className="icofont-linkedin"></i> */}
                          <img
                            src="/images/linkedin.png"
                            className="img-fluid"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank">
                          {/* <i className="icofont-instagram"></i> */}
                          <img
                            src="/images/instagram.png"
                            className="img-fluid"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-2">
              <div className="footer-item">
                <div className="footer-quick">
                  <h3>Our Facilities</h3>
                  <ul>
                    <li>
                      <Link to="/radiology">Radiology</Link>
                    </li>
                    <li>
                      <Link to="/laboratories">Laboratories</Link>
                    </li>
                    <li>
                      <Link to="/fetal-medicine">Fetal Medicine</Link>
                    </li>
                    <li>
                      <Link to="/liver-fibroscan">Liver FibroScan</Link>
                    </li>
                    <li>
                      <Link to="/cardiology">Cardiology</Link>
                    </li>
                    <li>
                      <Link to="/molecular-diagnostics">
                        Molecular Diagnostics & Genetics
                      </Link>
                    </li>
                    <li>
                      <Link to="/neurology">Neurology</Link>
                    </li>
                    <li>
                      <Link to="/pulmonology">Pulmonology</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copyright-area">
        <div className="container">
          <div className="copyright-item">
            <div className="row">
              <div className="col-md-6">
                <p className="nk__text-left">
                  © 2023 Dr. Doda's Diagnostics & Healthcare
                </p>
              </div>
              <div className="col-md-6">
                <p className="nk__text-right">
                  Design & Developed by{" "}
                  <a href="#" target="_blank">
                    Asterisk Serve
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
