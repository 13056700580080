import { createContext, useReducer } from "react";
import {
  healthPackagesReducer,
  healthPackagesInitialState,
} from "../reducers/healthPackagesReducer";

export const healthPackagesContext = createContext(null);

export const HealthPackagesContextProvider = (props) => {
  const [state, dispatch] = useReducer(
    healthPackagesReducer,
    healthPackagesInitialState
  );

  return (
    <healthPackagesContext.Provider value={{ state, dispatch }}>
      {props.children}
    </healthPackagesContext.Provider>
  );
};
