import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const MolecularDiagnosticsPage = () => {
  return (
    <React.Fragment>
      <div className="nk__qualityAssurance ptb-70 nk__radiology_page nk__faclities_sub-page">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Molecular Diagnostics & Genetics</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="nk__tab_content">
                <div className="table-responsive mb-5">
                  <table
                    class="table table-bordered table-hover"
                    cellspacing="0"
                    cellpadding="0"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <th width="25%">Test Name</th>
                        <th width="25%">Quantity of Blood Required</th>
                        <th width="12%">Turnaround Time (TAT)</th>
                        <th>Container</th>
                      </tr>
                      <tr>
                        <td>HLA B-27 (Flowcytometry/PCR)</td>
                        <td>2 ml</td>
                        <td>24 Hrs</td>
                        <td>EDTA vacutainer</td>
                      </tr>
                      <tr>
                        <td>HBV DNA PCR (QUALITATIVE)</td>
                        <td>2 ml</td>
                        <td>2 Days</td>
                        <td>EDTA & Plain vacutainer</td>
                      </tr>
                      <tr>
                        <td>HPV DNA PCR</td>
                        <td>---</td>
                        <td>2 Days</td>
                        <td>Cervical smear / LBC</td>
                      </tr>
                      <tr>
                        <td>HCV RNA (PCR) quantitative</td>
                        <td>2 ml</td>
                        <td>2 Days</td>
                        <td>EDTA & SST II Advance</td>
                      </tr>
                      <tr>
                        <td>GENE XPERT TB & RIFAMPICIN RESISTANCE</td>
                        <td>---</td>
                        <td>Same Day</td>
                        <td>Body Fluid</td>
                      </tr>
                      <tr>
                        <td>HBV DNA QUANTITATIVE (PCR)</td>
                        <td>2 ml</td>
                        <td>2 Days</td>
                        <td>EDTA & Plain vacutainer</td>
                      </tr>
                      <tr>
                        <td>HCV RNA GENOTYPE</td>
                        <td>2 ml</td>
                        <td>2 Days</td>
                        <td>EDTA & Plain vacutainer</td>
                      </tr>
                      <tr>
                        <td>HCV RNA QUALITATIVE (PCR)</td>
                        <td>2 ml</td>
                        <td>2 Days</td>
                        <td>EDTA & Plain vacutainer</td>
                      </tr>
                      <tr>
                        <td>HPV GENOTYPING</td>
                        <td>---</td>
                        <td>2 Days</td>
                        <td>Cervical smear / LBC</td>
                      </tr>
                      <tr>
                        <td>INFLUENZA VIRUS (H1N1)</td>
                        <td>---</td>
                        <td>2 Days</td>
                        <td>Nasal/Throat Swab</td>
                      </tr>
                      <tr>
                        <td>JAK 2-617 F MUTATION, QUALITATIVE</td>
                        <td>2 ml</td>
                        <td>2 Days</td>
                        <td>EDTA</td>
                      </tr>
                      <tr>
                        <td>
                          TB PCR (Pus / Body Fluids/ Blood / Endometrial tissue)
                        </td>
                        <td>3-5 ml</td>
                        <td>2 Days</td>
                        <td>Body Fluid</td>
                      </tr>
                      <tr>
                        <td>Chikungunya PCR</td>
                        <td>2 ml</td>
                        <td>2 Days</td>
                        <td>EDTA</td>
                      </tr>
                      <tr>
                        <td>COVID-19 SARS-CoV-2 RT-PCR</td>
                        <td>---</td>
                        <td>Same Day</td>
                        <td>Nasopharyngeal & Oropharyngeal Swab in VTM</td>
                      </tr>
                      <tr>
                        <td>COVID-19 SARS-CoV CBNAAT</td>
                        <td>---</td>
                        <td>Same Day</td>
                        <td>Nasopharyngeal & Oropharyngeal Swab in VTM</td>
                      </tr>
                      <tr>
                        <td>H1N1 RT-PCR</td>
                        <td>---</td>
                        <td>Same Day</td>
                        <td>Nasopharyngeal & Oropharyngeal Swab in VTM</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MolecularDiagnosticsPage;
