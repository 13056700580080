import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const HealthPackagesCategoryPage = () => {
  return (
    <React.Fragment>
      <div className="nk__waves top">
        <img
          src="/images/purple-wave-desktop-top.svg"
          alt="purple-wave-desktop-top"
        />
      </div>
      <div className="health-package-area ptb-35">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Routine Health Packages</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-25">
              <div className="hp-item">
                <div className="health-package">
                  <div className="hp-content">
                    <h3>
                      <img
                        src="/images/health-packages/hp-1.png"
                        alt=""
                        className="img-fluid"
                      />
                      MINI HEALTH
                    </h3>
                    <p>
                      <i className="icofont-info-circle"></i>CBC | Blood Urea |
                      Creatinine | Cholesterol | Urine Routine
                    </p>
                    <p>
                      <i className="icofont-ui-calendar"></i>Daily
                    </p>
                    <p>
                      <i className="icofont-settings-alt"></i>Total number of
                      tests 5
                    </p>
                  </div>
                  <div className="hp-price">
                    <div className="row align-items-center">
                      <div className="col-6">
                        <span className="discounted_price">Rs. 1100/-</span>
                      </div>
                      <div className="col-6 nk__text-right">
                        <a
                          href="https://wa.me/919643106760"
                          target="_blank"
                          className="book__now"
                          rel="noreferrer"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-25">
              <div className="hp-item">
                <div className="health-package">
                  <div className="hp-content">
                    <h3>
                      <img
                        src="/images/health-packages/hp-1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      BRONZE HEALTH
                    </h3>
                    <p>
                      <i className="icofont-info-circle"></i>Glucose Fasting |
                      Thyroid Profile | LFT | KFT | Lipid Profile
                    </p>
                    <p>
                      <i className="icofont-ui-calendar"></i>Daily
                    </p>
                    <p>
                      <i className="icofont-settings-alt"></i>Total number of
                      tests 5
                    </p>
                  </div>
                  <div className="hp-price">
                    <div className="row align-items-center">
                      <div className="col-6">
                        <span className="discounted_price">Rs. 1850/-</span>
                      </div>
                      <div className="col-6 nk__text-right">
                        <a
                          href="https://wa.me/919643106760"
                          target="_blank"
                          className="book__now"
                          rel="noreferrer"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-25">
              <div className="hp-item">
                <div className="health-package">
                  <div className="hp-content">
                    <h3>
                      <img
                        src="/images/health-packages/hp-1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      SILVER HEALTH
                    </h3>
                    <p>
                      <i className="icofont-info-circle"></i>Glucose Fasting |
                      LFT | KFT | Lipid Profile | HbA1c | CBC
                    </p>
                    <p>
                      <i className="icofont-ui-calendar"></i>Daily
                    </p>
                    <p>
                      <i className="icofont-settings-alt"></i>Total number of
                      tests 6
                    </p>
                  </div>
                  <div className="hp-price">
                    <div className="row align-items-center">
                      <div className="col-6">
                        <span className="discounted_price">Rs. 2350/-</span>
                      </div>
                      <div className="col-6 nk__text-right">
                        <a
                          href="https://wa.me/919643106760"
                          target="_blank"
                          className="book__now"
                          rel="noreferrer"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-25">
              <div className="hp-item">
                <div className="health-package">
                  <div className="hp-content">
                    <h3>
                      <img
                        src="/images/health-packages/hp-1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      GOLD HEALTH
                    </h3>
                    <p>
                      <i className="icofont-info-circle"></i>Glucose Fasting |
                      HbA1c | KFT | LFT | Lipid Profile | Thyroid Profile |
                      Hemogram | CRP
                    </p>
                    <p>
                      <i className="icofont-ui-calendar"></i>Daily
                    </p>
                    <p>
                      <i className="icofont-settings-alt"></i>Total number of
                      tests 8
                    </p>
                  </div>
                  <div className="hp-price">
                    <div className="row align-items-center">
                      <div className="col-6">
                        <span className="discounted_price">Rs. 2550/-</span>
                      </div>
                      <div className="col-6 nk__text-right">
                        <a
                          href="https://wa.me/919643106760"
                          target="_blank"
                          className="book__now"
                          rel="noreferrer"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-25">
              <div className="hp-item">
                <div className="health-package">
                  <div className="hp-content">
                    <h3>
                      <img
                        src="/images/health-packages/hp-1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      PLATINUM HEALTH
                    </h3>
                    <p>
                      <i className="icofont-info-circle"></i>Glucose Fasting |
                      KFT | LFT | Lipid Profile | Thyroid Profile | Hemogram |
                      HbA1c | Vitamin D | Vitamin B12 | Urine Routine | Iron
                      Studies | Amylase | CRP
                    </p>
                    <p>
                      <i className="icofont-ui-calendar"></i>Daily
                    </p>
                    <p>
                      <i className="icofont-settings-alt"></i>Total number of
                      tests 13
                    </p>
                  </div>
                  <div className="hp-price">
                    <div className="row align-items-center">
                      <div className="col-6">
                        <span className="discounted_price">Rs. 5500/-</span>
                      </div>
                      <div className="col-6 nk__text-right">
                        <a
                          href="https://wa.me/919643106760"
                          target="_blank"
                          className="book__now"
                          rel="noreferrer"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-5">
              <img
                src="/images/health-packages-info.jpg"
                alt="health-packages-info"
                className="img-fluid packages-info-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="nk__waves bottom">
        <img
          src="/images/purple-wave-desktop-bottom.svg"
          alt="purple-wave-desktop-bottom"
        />
      </div>
    </React.Fragment>
  );
};

export default HealthPackagesCategoryPage;
