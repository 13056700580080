import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const CardiologyPage = () => {
  return (
    <React.Fragment>
      <div className="nk__qualityAssurance ptb-70 nk__radiology_page nk__faclities_sub-page">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Cardiology</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="nk__tab_content">
                <h3>Non-Invasive Cardiac Lab</h3>
                <p>
                  <strong>4D ECHO & Stress Echo</strong> machine by Philips is a
                  dedicated Cardiac ultrasound machine which can scan the heart
                  to get comprehensive diagnostic information. This machine
                  gives the ability to deliver size, resolution and volume rate.
                  With the Stress applications physicians can view the
                  ventricles under stress. High quality images enable us to use
                  this machine for screening as well as diagnostic purposes to
                  detect or rule out heart disease.
                </p>
                <p>
                  <strong>Treadmill test (TMT)</strong> or exercise stress test
                  is one of the commonest forms of stress tests for diagnostic
                  evaluation of coronary artery disease. The cardiac stress test
                  is done with heart stimulation, by exercise on a treadmill.
                  The level of mechanical stress is progressively increased by
                  adjusting the difficulty (steepness of the slope) and speed.
                  The test administrator or attending physician examines the
                  symptoms and blood response. The TMT machine uses special
                  algorithms and measures multiple risk factors which can be
                  reported simultaneously, giving a comprehensive view of the
                  risk, and also can assess the risk of Sudden Cardiac Death
                </p>
                <p>
                  <img
                    src="/images/facilities/cardiology.jpg"
                    alt=""
                    className="img-fluid mt-3"
                  />
                </p>
                <p className="nk__content_title">ECG</p>
                <p>
                  ECG is a simple, test that records the heart`s electrical
                  activity. It helps to understand how the heart works. With
                  each heartbeat, an electrical signal spreads from the top of
                  the heart to the bottom, causing the heart to contract and
                  pump blood.
                </p>
                <p className="nk__content_title text-center nk__block">
                  Instructions for Patients
                </p>
                <p>
                  We recommend you take prior appointment for ECHO and TMT to
                  avoid waiting time at the centre.
                </p>
                <p>
                  Do not come for these investigations immediately after taking
                  meals.Please bring your previous ECG, Xray Chest and any other
                  investigation that you got done earlier. It is important for
                  your study.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardiologyPage;
