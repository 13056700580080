import React from "react";

const AnanyaDodaSarinDetailsPage = () => {
  return (
    <div className="doctor-details-area ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="doctor-details-item doctor-details-left">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-12">
                  <img src="/images/dr-ananya-doda.png" alt="Doctor" />
                </div>
                <div className="col-md-6 col-lg-12">
                  <div className="doctor-details-contact">
                    <h3>Contact info</h3>
                    <ul>
                      <li>
                        <i className="icofont-ui-call"></i>
                        <a href="tel:+919643106759">Call: +91 9643106759</a>
                      </li>
                      <li>
                        <i className="icofont-ui-message"></i>
                        <a href="mailto:dodahealthcare@gmail.com">
                          dodahealthcare@gmail.com
                        </a>
                      </li>
                      <li>
                        <i className="icofont-location-pin"></i>
                        23-B, Pusa Road, Opposite Metro Pillar 115, New Delhi -
                        110005, India.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="doctor-details-item">
              <div className="doctor-details-right">
                <div className="doctor-details-biography">
                  <h3>DR. ANANYA DODA SARIN</h3>
                  <p>ASSOCIATE DIRECTOR & HEAD OF LABS</p>
                </div>

                <div className="doctor-details-biography">
                  <p>
                    Dr. Ananya Doda Sarin is a Consultant Pathologist & the
                    Associate Director at Dr. Doda’s Diagnostics & Healthcare in
                    Delhi. She did her MD from Himalayan Institute of Medical
                    Sciences, Dehradun in 2017 and Senior Residency at Dr. RML
                    Hospital, New Delhi.
                  </p>

                  <p>
                    Ananya has also received exceptional training in Breast
                    Pathology, GI Pathology and Cytopathology at Massachusetts
                    General Hospital, Boston (Harvard Medical College). She has
                    been trained in Total Laboratory Quality Management & ISO
                    15189:2012 as well as ISO 15189:2022 by Foundation for
                    Quality India at Sir Ganga Ram Hospital, New Delhi and is an
                    Internal auditor.
                  </p>

                  <p>
                    Her core areas of interest include Breast Pathology,
                    Oncopathology, Molecular Diagnostics and Quality Assurance.
                    Her research in the area of ‘Carbapenem resistance in
                    E.coli’ has been acknowledged by ICMR and GenBank with gene
                    sequences registered to her name; she has won the Young
                    Scientist Award for the same.
                  </p>

                  <p>
                    She also had a major contribution in setting up our
                    Molecular Diagnostics Lab, in getting our institution the
                    highest accreditations set by the Quality Council of India:
                    National Accreditation Board for Hospitals & Healthcare
                    Providers (NABH) and National Accreditation Board for
                    Testing and Calibration Laboratories (NABL) as well as
                    initiating futuristic genetic testing in our lab.
                  </p>

                  <p>
                    She has been rigorously reporting COVID-19 cases and Dr.
                    Doda’s Lab has surpassed reporting lakhs of RT-PCR COVID-19
                    tests since the beginning of the pandemic.
                  </p>

                  <p>
                    She strives for academic excellence, has won distinctions as
                    a student and has presented many research papers at various
                    International & National conferences. She is committed to
                    provide laboratory services to patients in a professional
                    manner at par with international standards.
                  </p>

                  <p>
                    She is a member of the Indian Academy of Cytology, Indian
                    Association of Pathologists and Microbiologists, Indian
                    Association of Pathologists and Microbiologists - Delhi
                    Chapter, Delhi Society of Hematology & CAHO- Consortium of
                    Accredited Healthcare Organisations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnanyaDodaSarinDetailsPage;
